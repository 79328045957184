import React, { useCallback, useMemo } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { getColumnNames } from './lib/utils';

import './styles.css';

/**
 * Компонент форматирующий десятичные дроби.
 *
 * Десятичные числа округляются до двух знаков после запятой.
 * Компонент форматирует значения во всех столбцах за исключения столбца с именем "name".
 *
 * @param columns {Array<{ name: string }>} - Список с описанием всех столбцов;
 * @param props {any} - Параметры для DataTypeProvider;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const DecimalFormatter = ({ columns, ...props }) => {
  const columnNames = useMemo(() => getColumnNames(columns), [columns]);
  const formatter = useCallback(({ value }) => {
    if (value === null || value === undefined) {
      return <span className="no-value">Значение отсутствует</span>
    }

    if (typeof value === 'number') {
      if (isNaN(value) || !isFinite(value)) {
        return <span className="no-value">Невозможно посчитать</span>;
      }

      return value.toLocaleString('ru', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return String(value);
  });

  return (
    <DataTypeProvider
      formatterComponent={formatter}
      {...props}
      for={columnNames}
    />
  );
};

DecimalFormatter.propTypes = {
  columns: arrayOf(shape({ name: string.isRequired })).isRequired,
};

DecimalFormatter.defaultProps = {};

export default DecimalFormatter;
