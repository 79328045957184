import React from 'react';
import { Button, CircularProgress, Fade, Grid, TextField, Typography, withStyles } from '@material-ui/core';

import logo from '../../assets/logo/fas-logo.jpg';
import { styles } from './styles';

const Login = ({ classes, handlePressEnter, ...props }) => (
  <Grid container className={classes.container}>
    <div className={classes.logotypeContainer}>
      <img src={logo} alt='logo' className={classes.logotypeImage} />
      <Typography className={classes.logotypeText}>Аналитическая Система Регулируемой Организации</Typography>
    </div>
    <div className={classes.formContainer}>
      <div className={classes.form}>
        <React.Fragment>
          <Typography variant='h1' className={classes.greeting}>
            Добро пожаловать!
          </Typography>
          <Fade in={props.error}>
            <Typography color='secondary' className={classes.errorMessage}>
              Вы ввели неверные учётные данные
            </Typography>
          </Fade>
          <TextField
            id='email'
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={props.username}
            onChange={props.handleInput('username')}
            onKeyPress={handlePressEnter}
            margin='normal'
            placeholder='Логин'
            type='email'
            fullWidth
          />
          <TextField
            id='password'
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={props.password}
            onChange={props.handleInput('password')}
            onKeyPress={handlePressEnter}
            margin='normal'
            placeholder='Пароль'
            type='password'
            fullWidth
          />
          <div className={classes.formButtons}>
            {props.isLoading
              ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              )
              : (
                <Button
                  disabled={
                    props.username.length === 0 ||
                    props.password.length === 0
                  }
                  onClick={props.handleLoginButtonClick}
                  variant='contained'
                  color='primary'
                  size='large'
                >
                  Войти
                </Button>
              )}
          </div>
        </React.Fragment>
      </div>
      <Typography color='primary' className={classes.copyright}>
        {`© 2014-${new Date().getFullYear()} ФБУ ИТЦ ФАС России. Все права защищены.`}
      </Typography>
    </div>
  </Grid>
);


export default withStyles(styles, { withTheme: true })(Login);
