import React from 'react';
import { Typography } from '@material-ui/core';

import './styles.css';

/**
 * Функциональный компонент SupportMenu для отображения информации о поддержке
 */
const SupportMenu = (props) => {
  return (
    <div className="support-wrapper">
      <Typography align="left" color="textPrimary" variant="h5">
        Техническая поддержка
      </Typography>
      <Typography align="left" color="textPrimary" variant="subtitle1">
        Вопросы и предложения
      </Typography>
      <Typography align="left" color="primary" variant="body1">
        <a href="mailto:asro@fas.gov.ru" className="email">asro@fas.gov.ru</a>
      </Typography>
    </div>
  )
};

export default SupportMenu;
