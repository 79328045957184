import React from 'react';

import { Table as BaseTable } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

/**
 * Компонент таблицы.
 *
 * @param classes {object} - CSS in JS classes;
 * @param props {object} - Props для компонента таблицы;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Table = ({ classes, ...props }) => (
  <BaseTable.Table
    {...props}
    className={classes.table}
  />
);

Table.propTypes = {};

Table.defaultProps = {};

export default withStyles(styles)(Table);
