import React from 'react';

import TableTemplate from '../TableTemplate';

import { BANDS, COLUMNS } from './lib/constants';

/**
 * Компонент для отображения данных тарифов на электрическую энергию.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ElectricityView = () => (
  <TableTemplate
    url="tariffs/ee"
    title="Тарифы на электрическую энергию за"
    columns={COLUMNS}
    bands={BANDS}
  />
);

ElectricityView.propTypes = {};

ElectricityView.defaultProps = {};

export default ElectricityView;
