import React from 'react';

import TableTemplate from '../TableTemplate';

import { BANDS, COLUMNS } from './lib/constants';

/**
 * Компонент для отображения данных тарифов на электрическую энергию для населения.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ElectricityNasView = () => (
  <TableTemplate
    url="tariffs/ee_nas"
    title="Тарифы для населения (INFO.KU.EE.GAS.SF.TF) за"
    columns={COLUMNS}
    bands={BANDS}
  />
);

ElectricityNasView.propTypes = {};

ElectricityNasView.defaultProps = {};

export default ElectricityNasView;
