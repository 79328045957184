import auth, { TOKEN } from '../../lib/auth';
import session from '../../lib/session';

export const initialState = {
  isLoading: false,
  isAuthenticated: localStorage.getItem(TOKEN),
  error: null,
};

export const START_LOGIN = 'Login/START_LOGIN';
export const LOGIN_SUCCESS = 'Login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'Login/LOGIN_FAILURE';
export const RESET_ERROR = 'Login/RESET_ERROR';
export const SIGN_OUT_SUCCESS = 'Login/SIGN_OUT_SUCCESS';

export const startLogin = () => ({
  type: START_LOGIN,
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

export const loginUser = (username, password) => dispatch => {
  dispatch(startLogin());

  auth.login(username, password)
    .then((result) => {
      if (result) dispatch(loginSuccess())
      else dispatch(loginFailure())
    })
};

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOut = () => dispatch => {
  localStorage.removeItem(TOKEN);
  delete (session.defaults.headers.common['Authorization']);
  dispatch(signOutSuccess());
};

export default function LoginReducer(state = initialState, { type }) {
  switch (type) {
    case START_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case RESET_ERROR:
      return {
        error: false,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
