export const COLUMNS = [
  { name: 'year', title: 'Год', remove: true },
  { name: 'name', title: 'Наименование' },
  { name: 'tar_potr_start', title: 'Первое полугодие' },
  { name: 'tar_potr_end', title: 'Второе полугодие' },
  { name: 'tar_nas_start', title: 'Первое полугодие' },
  { name: 'tar_nas_end', title: 'Второе полугодие' },
  { name: 'min_tar_potr_start', title: 'Первое полугодие' },
  { name: 'min_tar_potr_end', title: 'Второе полугодие' },
  { name: 'max_tar_potr_start', title: 'Первое полугодие' },
  { name: 'max_tar_potr_end', title: 'Второе полугодие' },
  { name: 'min_tar_nas_start', title: 'Первое полугодие' },
  { name: 'min_tar_nas_end', title: 'Второе полугодие' },
  { name: 'max_tar_nas_start', title: 'Первое полугодие' },
  { name: 'max_tar_nas_end', title: 'Второе полугодие' },
];

export const BANDS = [
  {
    title: 'Средневзвешенный тариф на тепловую энергию для потребителей (руб/Гкал). Без НДС',
    children: [{ columnName: 'tar_potr_start' }, { columnName: 'tar_potr_end' }],
  },
  {
    title: 'Средневзвешенный тариф на тепловую энергию для населения (руб/Гкал). С НДС',
    children: [{ columnName: 'tar_nas_start' }, { columnName: 'tar_nas_end' }],
  },
  {
    title: 'Минимальный тариф на тепловую энергию для потребителей (руб/Гкал). Без НДС',
    children: [{ columnName: 'min_tar_potr_start' }, { columnName: 'min_tar_potr_end' }],
  },
  {
    title: 'Максимальный тариф на тепловую энергию для потребителей (руб/Гкал). Без НДС',
    children: [{ columnName: 'max_tar_potr_start' }, { columnName: 'max_tar_potr_end' }],
  },
  {
    title: 'Минимальный тариф на тепловую энергию для населения (руб/Гкал). С НДС',
    children: [{ columnName: 'min_tar_nas_start' }, { columnName: 'min_tar_nas_end' }],
  },
  {
    title: 'Максимальный тариф на тепловую энергию для населения (руб/Гкал). С НДС',
    children: [{ columnName: 'max_tar_nas_start' }, { columnName: 'max_tar_nas_end' }],
  },
];
