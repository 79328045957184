import React from 'react';

import { TemplateConnector, Plugin } from '@devexpress/dx-react-core';

import Container from './components/Container';


/**
 * Плагин для работы внутри Toolbar, является коннектором для данных, необходимых для ColumnChooser.
 */
const Connector = (props) => (
  <Plugin name="ColumnChooser">
      <TemplateConnector>
        {/* Тут магия dev express с получением данных, см. документацию https://devexpress.github.io/devextreme-reactive/react/core/docs/reference/action/ */}
        { ({ headerBands, hideColumns, hiddenColumnNames, isColumnTogglingEnabled }, {toggleColumnVisibility}) =>
          <Container
            headerBands={headerBands}
            columns={hideColumns}
            hiddenColumnNames={hiddenColumnNames}
            isColumnTogglingEnabled={isColumnTogglingEnabled}
            toggleColumnVisibility={toggleColumnVisibility}
            {...props}
          />
        }
      </TemplateConnector>
  </Plugin>
)


export default Connector;
