import React from 'react';

import { TableBandHeader } from '@devexpress/dx-react-grid-material-ui';

import './styles.css';

/**
 * Компонент объедененной ячейки заголовка таблицы.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BandCell = (props) => (
    <TableBandHeader.Cell {...props} className="band-cell"/>
);

BandCell.propTypes = {};
BandCell.defaultProps = {};

export default BandCell;
