import React from 'react';
import Typography from '../Wrappers/Wrappers';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';

/**
 * Функциональный компонент ProfileMenu для выполнения информации пользователя
 */
const ProfileMenu = (props) => {
  const { classes } = props;
  return (
    <div>
      <div className={classes.profileMenuUser}>
        <Typography variant="h4" weight="medium">
          Иванов Иван
        </Typography>
        <Typography
          className={classes.profileMenuLink}
          color="primary"
        >
          your@email.com
        </Typography>
      </div>
      <div className={classes.profileMenuUser}>
        <Typography
          className={classes.profileMenuLink}
          color="primary"
          onClick={props.signOut}
        >
          Выйти
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(ProfileMenu);
