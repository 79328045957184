export const COLUMNS = [
  { name: 'year', title: 'Год', remove: true },
  { name: 'name', title: 'Наименование' },
  { name: 'l_population', title: 'Вид населения' },
  { name: 'l_stove', title: 'Тип плиты' },
  { name: 'l_tfe_start', title: 'Первое полугодие' },
  { name: 'l_tfe_end', title: 'Второе полугодие' },
  { name: 'l_tfn_start', title: 'Первое полугодие' },
  { name: 'l_tfn_end', title: 'Второе полугодие' },
];

export const BANDS = [
  {
    title: 'Экономически обоснованный тариф (руб). С НДС',
    children: [{ columnName: 'l_tfe_start' }, { columnName: 'l_tfe_end' }],
  },
  {
    title: 'Тариф для населения с учетом НДС и прочих выплат, применяемых при начислении платежа (руб)',
    children: [{ columnName: 'l_tfn_start' }, { columnName: 'l_tfn_end' }],
  },
];
