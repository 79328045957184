import { range } from '../../../lib/utils';

export const parseBands = (bands) => {
  const staticBands = [];
  const dynamicBands = [];

  for (const band of bands) {
    if (band.dynamic) {
      dynamicBands.push(band);
    } else {
      staticBands.push(band);
    }
  }

  return [staticBands, dynamicBands];
};

export const buildDynamicColumns = (dynamicBand, { start, end }) => {
  const columns = [];
  const { name } = dynamicBand;
  const bandsChildren = [];

  let customName = `${name}_${start}`;

  bandsChildren.push({ columnName: customName });
  columns.push({ name: customName, title: start, defaultHide: true });

  for (const year of range(start + 1, end)) {
    const previous = year - 1;

    customName = `${name}_${year}`;
    bandsChildren.push({ columnName: customName });
    columns.push({ name: customName, title: year, defaultHide: true });

    customName = `${name}_${year}_${previous}`;
    bandsChildren.push({ columnName: customName });
    columns.push({ name: customName, title: `Отклонение ${year} / ${previous} в %`, defaultHide: true });
  }

  if (start + 1 !== end) {
    customName = `${name}_${end}_${start}_common`;
    bandsChildren.push({ columnName: customName });
    columns.push({ name: customName, title: `Отклонение ${end} / ${start} в %`, defaultHide: true });
  }

  return [columns, bandsChildren];
};

export const buildValues = (staticColumns, dynamicColumns, values, { start, end }) => values.map((value) => {
  const valueCopy = {};

  for (const { name } of staticColumns) {
    valueCopy[name] = value[name];
  }

  for (const name of dynamicColumns) {
    valueCopy[`${name}_${start}`] = value[name][start];

    for (const year of range(start + 1, end)) {
      const previous = year - 1;

      valueCopy[`${name}_${year}`] = value[name][year];
      valueCopy[`${name}_${year}_${previous}`] = (value[name][year] / value[name][previous]) * 100;
    }

    if (start + 1 !== end) {
      valueCopy[`${name}_${end}_${start}_common`] = (value[name][end] / value[name][start]) * 100;
    }
  }

  return valueCopy;
});
