/**
 * Функция для получения списка названий столбцов.
 *
 * Из списка объектов функция достает название столбцов
 *  и отбрасывает столбец с названием "name".
 *
 * @param columns {Array<{ name: string }>} - Список с описанием всех столбцов;
 * @return {Array<string>}
 */
export const getColumnNames = (columns) => {
  const names = [];

  for (const { name } of columns) {
    if (name !== 'name') {
      names.push(name)
    }
  }

  return names;
};
