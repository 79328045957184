/**
 * Функция производит переформатирование структуры колонок.
 *
 * Колонки, которые объединены в группы визуально, помещаются в единый объект.
 *
 * @param {Array} bands - Массив содержащий информацию о группировке колонок в группы;
 * @param {Array} columns - Массив с информацией о колонках;
 * @param {Array} hiddenColumnNames - Массив с уже скрытыми колонкам;
 * @returns - Массив данных, в котором содержатся переработанная структура колонок, которые уже объединены в группы.
 */
export const getColumnChooserItems = (bands, columns, hiddenColumnNames) => {
  const nonBandedColumns = columns
    .filter(
      ({ name }) =>
        !bands.some(({ children }) =>
          children.some(({ columnName }) => name === columnName)
        )
    )
    .map((column) => ({
      column,
      name: column.name,
      hidden: hiddenColumnNames.includes(column.name)
    }));
  const bandedColumns = bands.map(({ title, children, name }) => ({
    name,
    column: {
      name: title,
    },
    columns: children,
    hidden: !children.length || hiddenColumnNames.includes(children[0].columnName)
  }));
  return [...nonBandedColumns, ...bandedColumns];
};
