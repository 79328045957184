import { createStyles } from '@material-ui/styles';

export const styles = theme => createStyles({
  link: {
    textDecoration: 'none',
    paddingLeft: theme.spacing.unit * 4.5,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkActive: {
    backgroundColor: theme.palette.background.light,
  },
  linkNested: {
    paddingLeft: 0,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    '&:hover, &:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
  linkIcon: {
    marginRight: theme.spacing.unit,
    color: theme.palette.text.secondary + '99',
    transition: theme.transitions.create('color'),
    width: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,
    color: theme.palette.text.secondary + 'CC',
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 16,
  },
  linkTextActive: {
    color: theme.palette.text.primary,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing.unit * 4.5 + 10,
  },
  sectionTitle: {
    marginLeft: theme.spacing.unit * 4.5,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
    height: 1,
    backgroundColor: '#D8D8D880',
  },
});
