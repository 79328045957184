import session from './session';

export const TOKEN = 'user_token';

export default {
  TOKEN,
  login(username, password) {
    return session.post('auth/login/', { username, password })
      .then(({ data }) => {
        this.saveToken(data.key);
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  logout() {
    return session.post('auth/logout/')
      .then(() => {
        this.delHeader();
        this.delToken();
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  setHeader(token) {
    session.defaults.headers.common['Authorization'] = `Token ${token}`;
  },

  delHeader() {
    delete (session.defaults.headers.common['Authorization']);
  },

  delToken() {
    localStorage.removeItem(TOKEN);
  },

  saveToken(token) {
    localStorage.setItem(TOKEN, token);
    this.setHeader(token);
  },

  checkToken() {
    return Boolean(localStorage.getItem(TOKEN));
  },
};
