import { useEffect, useRef } from 'react';

import { buildDynamicColumns, buildValues, parseBands } from '../lib/utils';

/**
 *
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CalculationByYear = ({ bands, columns, hiddenColumns, period, values, onChange }) => {
  const previousHiddenColumns = useRef();
  const previousVisibleColumns = useRef([]);
  const previousPeriod = useRef();

  useEffect(() => {
    if (
      period &&
      (previousHiddenColumns.current !== hiddenColumns ||
      previousPeriod.current !== period)
    ) {
      const dynamicBands = parseBands(bands)[1];
      const newColumns = [...columns];
      const visibleColumns = [];


      for (const dynamicBand of dynamicBands) {

        const [dynamicColumn, bandsChildren] = buildDynamicColumns(dynamicBand, period);

        if (period !== previousPeriod.current) {
          dynamicBand.children = bandsChildren;
          if (!previousVisibleColumns.current.includes(dynamicBand.name)) {
            hiddenColumns.push(...dynamicColumn.map(({name}) => name))
          }
        }

        if (hiddenColumns.indexOf(dynamicBand.children[0].columnName) === -1) {
          previousVisibleColumns.current.push(dynamicBand.name);
          visibleColumns.push(dynamicBand.name);
          newColumns.push(...dynamicColumn);
        } else {
          previousVisibleColumns.current = previousVisibleColumns.current.filter((name) => name !== dynamicBand.name);
        }
      }

      const newValues = buildValues([{name: 'odp_name', title: 'Год'}, ...columns], visibleColumns, values, period);

      previousHiddenColumns.current = hiddenColumns;
      previousPeriod.current = period;

      onChange(newValues, newColumns);
    }
  }, [bands, columns, period, hiddenColumns, values, onChange])

  return null;
};

CalculationByYear.propTypes = {};

CalculationByYear.defaultProps = {};

export default CalculationByYear;
