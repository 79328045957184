import session from './session';
import auth from './auth';

export const ANALYTICS_URL = 'api_analytics'

export default {
  login(username, password) {
    return session.post('/auth/login/', { username, password });
  },
  logout() {
    return session.post('/auth/logout/', {});
  },
  getContent(url, sendParams) {
    return new Promise((resolve, reject) => {
      let params = sendParams;
      if (!sendParams) params = {};
      session
        .get(`${ANALYTICS_URL}/${url}/`, { params })
        .then(post => resolve(post))
        .catch(e => {
          if (e.response !== undefined && e.response.status === 403) {
            auth.logout().then().catch();
          }
          reject(e);
        });
    });
  },
  sendContent(url, aMethod, aData) {
    return new Promise((resolve, reject) => {
      let method = aMethod;
      let data = aData;
      if (!method) method = 'post';
      if (!data) data = {};
      session({ method, url, data })
        .then(post => resolve(post))
        .catch(e => {
          if (e.response !== undefined && e.response.status === 403) {
            auth.logout().then().catch();
          }
          reject(e);
        });
    });
  },
  sendData(url, data = {}, method = 'post') {
    return new Promise((resolve, reject) => {
      session({ url, method, data })
        .then(response => resolve(response))
        .catch(errResponse => {
          if (errResponse.response !== undefined && errResponse.response.status === 403) {
            auth.logout().then().catch();
          }
          reject(errResponse);
        });
    });
  },
};
