/**
 * Функция для генерации последовательности между двумя числами.
 *
 * @param {Number} start - Число для начала генерации последовательности;
 * @param {Number} end - Число, которым последовательность заканчивается;
 * @returns массив последовательности.
 */
export const range = (start, end) => {
  const result = [];

  for (let current = start; current <= end; current++) {
    result.push(current);
  }

  return result;
}

/**
 * Функция для исключения значения из массива.
 *
 * @param {Array} source - Массив, из которого необходимо исключить значение;
 * @param {any} value - Значение, которое будет исключено из массива, если оно там найдено;
 * @returns Возвращает новый массив, из которого уже исключено указанное значение;
 */
export const excludeValue = (source, value) => source.filter(v => v !== value);
