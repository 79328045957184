import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, node, shape, string } from 'prop-types';

import { Grid, Table, TableBandHeader, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { LinearProgress, Paper, Typography, withStyles } from '@material-ui/core';
import { eq } from 'handy-filter';
import useFilter from 'handy-filter-hook';

import api from '../../../lib/api';
import Select from '../../Select';

import BandCell from './components/BandCell';
import Container from './components/Container';
import DecimalFormatter from './components/DecimalFormatter';
import Filtering from './components/Filtering';
import Paging from './components/Paging';
import Toolbar from './components/Toolbar';
import Sorting, { SortingLabel } from './components/Sorting';
import TableComponent from './components/Table';

import { ALL_DISTRICTS, HEADER_MESSAGES, TABLE_MESSAGES } from './lib/constants';
import { getColumnExtensions, parse, removeColumns } from './lib/utils';

import styles from './styles';

/**
 * Компонент-шаблон для отображения данных тарифов.
 *
 * Шаблон предоставляет заранее настроенную пагинацию, фильтрацию, сортировку и форматирование.
 *
 * @param classes {object} - CSS in JS classes;
 * @param url {string} - API endpoint для получения данных;
 * @param title {string} - Заголовок таблицы;
 * @param columns {Array<{ name: string, title: React.ReactNode }>} - Описание столбцов таблицы;
 * @param bands {Array<{
 *  title: React.ReactNode,
 *  children: Array<{ columnName: string }>,
 * }>} - Описание столбцов, которые необходимо объединить;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TableTemplate = ({ classes, url, title, columns, bands }) => {
  const [filteredData, setCondition, setData] = useFilter({ init: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [pureData, setPureData] = useState(null);
  const years = useRef([]);
  const districts = useRef([]);
  const allTariffs = useRef();
  const tableColumns = useMemo(() => removeColumns(columns), [columns]);
  const extensions = useMemo(() => getColumnExtensions(tableColumns), [tableColumns]);

  /**
   * Обработчик изменения выбранного года.
   */
  const onYearChangeHandler = useCallback((selectedYear) => {
    setData(allTariffs.current[selectedYear]);
  }, [setData]);

  /**
   * Обработчик изменения выбранного федерального округа.
   */
  const onDistrictChangeHandler = useCallback((value) => {

    setCondition(eq('district', value === ALL_DISTRICTS ? '__any__' : value), 'district');
  }, [setCondition]);

  useEffect(() => {
    (async () => {
      const { data } = await api.getContent(url);
      const [groupedByYear, districtList] = parse(data);

      setPureData(data);
      districts.current = [ALL_DISTRICTS, ...districtList.sort()];
      allTariffs.current = groupedByYear;
      years.current = Object.keys(groupedByYear).sort().reverse();

      setData(groupedByYear[years.current[0]]);
      setIsLoading(false);
    })();
  }, [url, setData]);

  return (
    <Paper className={classes.paperMain}>
      <Typography variant="h5" align="center">
        {title}
        <Select className={classes.year} items={years.current} onChange={onYearChangeHandler}/>
        <Select className={classes.districts} items={districts.current} onChange={onDistrictChangeHandler}/>
      </Typography>

      <LinearProgress style={{ opacity: isLoading ? 1 : 0 }}/>

      <Grid rows={filteredData} columns={tableColumns}>
        {/*
          Здесь важен порядок подключения компонентов-плагинов.
          Если его поменять может перестать работать сортировка, фильтрация или пагинация.
        */}
        <DecimalFormatter columns={tableColumns}/>
        <Sorting/>
        <Paging/>

        <Table
          containerComponent={Container}
          tableComponent={TableComponent}
          columnExtensions={extensions}
          messages={TABLE_MESSAGES}
        />

        <Toolbar exportData={pureData} hideColumns={tableColumns} sourceColumns={columns} bands={bands} />
        <TableHeaderRow showSortingControls messages={HEADER_MESSAGES} sortLabelComponent={SortingLabel}/>
        <TableBandHeader columnBands={bands} cellComponent={BandCell}/>

        <Filtering onChange={setCondition}/>
      </Grid>
    </Paper>
  );
};

TableTemplate.propTypes = {
  url: string.isRequired,
  title: string.isRequired,
  columns: arrayOf(shape({ name: string.isRequired, title: node.isRequired })).isRequired,
  bands: arrayOf(shape({
    title: node.isRequired,
    children: arrayOf(shape({ columnName: string.isRequired })),
  })).isRequired,
};

TableTemplate.defaultProps = {};

export default withStyles(styles)(TableTemplate);
