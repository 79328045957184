import { createStyles } from '@material-ui/core';

export default () => createStyles({
  sortLabel: {
    '& > span > svg': {
      opacity: 1,
      '@media print': {
        display: 'none',
      },
    },
  },
});
