import React from 'react';

import { withStyles } from '@material-ui/core';
import { Table } from '@devexpress/dx-react-grid-material-ui';

import styles from './styles';

/**
 * Компонент-контейнер для таблицы.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Container = ({ children, classes, ...props }) => (
  <Table.Container className={classes.container} {...props}>
    {children}
  </Table.Container>
);

Container.propTypes = {};

Container.defaultProps = {};

export default withStyles(styles)(Container);
