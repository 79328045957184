export const COLUMNS = [
  { name: 'mo_name', title: 'Муниципальное образование, наименование' },
  { name: 'mr_name', title: 'Муниципальный район, наименование' },
  { name: 'org_name', title: 'Справочник регулируемых организаций, наименовани' },
  { name: 'l8_2', title: 'Вид(-ы) теплоносителя(-ей) / Вид воды / Вид сточных вод / Тип системы' },
  { name: 'l4', title: 'Признак плательщика НДС', defaultHide: true },
  { name: 'l8_7', title: 'Наличие статуса ЕТО / ГП / РО', defaultHide: true },
  { name: 'l_prevailing_share_value', title: 'Государственное и (или) муниципальное участие в юридическом лице. Преобладающий тип собственности в юридическом лице', defaultHide: true },
  { name: 'l_state_share_existence', title: 'Государственное и (или) муниципальное участие в юридическом лице. Наличие', defaultHide: true },
  { name: 'l_state_share_value', title: 'Государственное и (или) муниципальное участие в юридическом лице. Сведения о доле', defaultHide: true },
  { name: 'l10', title: 'Метод установления тарифа', defaultHide: true },
  { name: 'l12_1', title: 'Справочно: срок действия тарифов. Количество лет', defaultHide: true },
  { name: 'l12_2_1', title: 'Справочно: срок действия тарифов. Дата начала. Год', defaultHide: true },
  { name: 'l12_3_1', title: 'Справочно: срок действия тарифов. Дата окончания. Год', defaultHide: true },
  { name: 'l13_1', title: 'Общая информация по МО. Наличие программы комплексного развития', defaultHide: true },
  { name: 'l13_2', title: 'Общая информация по МО. Наличие схемы теплоснабжения / водоснабжения / водоотведения', defaultHide: true },
  { name: 'l45_1', title: 'Численность проживающих. Общее число жителей в МО', defaultHide: true },
  { name: 'l45_2', title: 'Численность проживающих. Число жителей, которым оказываются услуги', defaultHide: true },
  { name: 'l8_1', title: 'Перепродажа (для ТС, ВС, ВО)', defaultHide: true},
];

export const BANDS = [
  {
    title: 'Пропущено сточных вод, всего',
    children: [],
    dynamic: true,
    name: 'l1'
  },
  {
    title: 'Хозяйственные нужды предприятия',
    children: [],
    dynamic: true,
    name: 'l1_1'
  },
  {
    title: 'По категориям потребителей. Всего',
    children: [],
    dynamic: true,
    name: 'l1_2'
  },
  {
    title: 'По категориям потребителей. Финансируемые из бюджетов всех уровней',
    children: [],
    dynamic: true,
    name: 'l1_2_1'
  },
  {
    title: 'По категориям потребителей. Финансируемые из бюджетов всех уровней. По приборам учёта',
    children: [],
    dynamic: true,
    name: 'l1_2_1_1'
  },
  {
    title: 'По категориям потребителей. Финансируемые из бюджетов всех уровней. По нормативам',
    children: [],
    dynamic: true,
    name: 'l1_2_1_2'
  },
  {
    title: 'По категориям потребителей. Прочие. По нормативам',
    children: [],
    dynamic: true,
    name: 'l1_2_3_2'
  },
  {
    title: 'По категориям потребителей. Население',
    children: [],
    dynamic: true,
    name: 'l1_2_2'
  },
  {
    title: 'По категориям потребителей. Население. По приборам учёта',
    children: [],
    dynamic: true,
    name: 'l1_2_2_1'
  },
  {
    title: 'По категориям потребителей. Население. По нормативам',
    children: [],
    dynamic: true,
    name: 'l1_2_2_2'
  },
  {
    title: 'По категориям потребителей. Прочие',
    children: [],
    dynamic: true,
    name: 'l1_2_3'
  },
  {
    title: 'По категориям потребителей. Прочие. По приборам учёта',
    children: [],
    dynamic: true,
    name: 'l1_2_3_1'
  },
  {
    title: 'Пропущено через cобственные очистные сооружения',
    children: [],
    dynamic: true,
    name: 'l2'
  },
  {
    title: 'Передано сточных вод другим канализациям. Всего',
    children: [],
    dynamic: true,
    name: 'l3'
  },
  {
    title: 'Передано сточных вод другим канализациям. На очистные сооружения',
    children: [],
    dynamic: true,
    name: 'l3_1'
  },
  {
    title: 'Сброшенные воды без очистки',
    children: [],
    dynamic: true,
    name: 'bl4'
  },
  {
    title: 'По категориям потребителей. Отпущено сточных вод другим канализационным сетям',
    children: [],
    dynamic: true,
    name: 'l1_2_4'
  },
];
