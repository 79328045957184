import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { arrayOf, node, shape, string } from 'prop-types';

import { LinearProgress, Paper, Typography, withStyles } from '@material-ui/core';
import { Grid, Table, TableBandHeader, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { gte, lte } from 'handy-filter';
import useFilter from 'handy-filter-hook';

import api from '../../../../lib/api';

import BandCell from '../components/BandCell';
import Paging from '../components/Paging';
import Filtering from '../components/Filtering';
import Container from '../components/Container';
import TableComponent from '../components/Table';
import DecimalFormatter from '../components/DecimalFormatter';
import Toolbar from '../components/Toolbar';
import Sorting, { SortingLabel } from '../components/Sorting';
import { HEADER_MESSAGES, TABLE_MESSAGES } from '../lib/constants';
import { getColumnExtensions } from '../lib/utils';

import Select from '../../../Select';

import CalculationProvider from './components/CalculationProvider';
import { range } from './lib/utils';

import styles from './styles';

/**
 * Компонент-шаблон для отображения данных  динамики роста.
 *
 * Шаблон предоставляет заранее настроенную пагинацию, фильтрацию, сортировку и форматирование.
 *
 * @param classes {object} - CSS in JS classes;
 * @param url {string} - API endpoint для получения данных;
 * @param title {string} - Заголовок таблицы;
 * @param columns {Array<{ name: string, title: React.ReactNode }>} - Описание столбцов таблицы;
 * @param bands {Array<{
 *  title: React.ReactNode,
 *  children: Array<{ columnName: string }>,
 * }>} - Описание столбцов, которые необходимо объединить;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TableTemplate = ({ classes, url, title, columns, bands, plugin }) => {
  const [filteredData, setCondition, setData] = useFilter({ init: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [period, setPeriod] = useState();
  const fromYear = useRef([]);
  const tillYear = useRef([]);
  const rawData = useRef([]);
  const dynamicColumns = useRef(columns);
  const extensions = useMemo(() => getColumnExtensions(dynamicColumns.current, '200px'), [dynamicColumns.current]);
  // В отчетах, это первый год с которых начинаются отчеты, чтобы не гемороиться
  // с вычислением этой даты, ее просто захардкодили
  const firstYear = 2014;
  const lastYear = new Date().getFullYear()

  const onYearChangeHandler = useCallback((name) => (year) => {
    setPeriod((prevState) => ({ ...prevState, [name]: year }));

    if (name === 'start') {
      tillYear.current = range(year + 1, lastYear).reverse();
      setCondition(gte('odp_name.max', year), 'start_year');
    } else {
      setCondition(lte('odp_name.min', year), 'end_year');
    }
  }, []);

  const onCalculationChangeHandler = useCallback((newValues, newColumns) => {
    setData(newValues);
    dynamicColumns.current = newColumns;
  }, [setData]);

  useEffect(() => {
    (async () => {
      const { data } = await api.getContent(url);

      fromYear.current = range(firstYear, lastYear - 1).reverse();
      tillYear.current = range(firstYear + 1, lastYear).reverse();

      rawData.current = data;

      unstable_batchedUpdates(() => {
        setData(data);
        setPeriod({ start: lastYear - 1, end: lastYear });
        setCondition(gte('odp_name.max', lastYear - 1), 'start_year');
        setCondition(lte('odp_name.min', lastYear), 'end_year');
        setIsLoading(false);
      });
    })();
  }, [url, setData]);

  return (
    <Paper className={classes.paperMain}>
      <Typography variant="h5" align="center">
        {title} с:
        <Select className={classes.year} items={fromYear.current} onChange={onYearChangeHandler('start')}/> по:
        <Select className={classes.year} items={tillYear.current} onChange={onYearChangeHandler('end')}/>
      </Typography>

      <LinearProgress style={{ opacity: isLoading ? 1 : 0 }}/>
      <Grid columns={dynamicColumns.current} rows={filteredData}>
        {/*
          Здесь важен порядок подключения компонентов-плагинов.
          Если его поменять может перестать работать сортировка, фильтрация или пагинация.
        */}
        <DecimalFormatter columns={dynamicColumns.current}/>
        <Sorting/>
        <Paging/>

        <CalculationProvider
          bands={bands}
          columns={columns}
          plugin={plugin}
          period={period}
          values={rawData.current}
          onChange={onCalculationChangeHandler}
        />

        <Table
          containerComponent={Container}
          tableComponent={TableComponent}
          messages={TABLE_MESSAGES}
          columnExtensions={extensions}
        />

        <Toolbar exportData={filteredData} hideColumns={columns} bands={bands} sourceColumns={dynamicColumns.current}/>
        <TableHeaderRow showSortingControls messages={HEADER_MESSAGES} sortLabelComponent={SortingLabel}/>
        <TableBandHeader columnBands={bands} cellComponent={BandCell}/>

        <Filtering onChange={setCondition}/>
      </Grid>
    </Paper>
  );
};

TableTemplate.propTypes = {
  url: string.isRequired,
  title: string.isRequired,
  columns: arrayOf(shape({ name: string.isRequired, title: node.isRequired })).isRequired,
  bands: arrayOf(shape({
    title: node.isRequired,
    children: arrayOf(shape({ columnName: string.isRequired })),
  })).isRequired,
};

TableTemplate.defaultProps = {};

export default withStyles(styles)(TableTemplate);
