import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import classnames from 'classnames';
import logo from '../../assets/logo/fas-logo.jpg';
import { styles } from './styles';

const NotFound404 = ({ classes }) => (
  <Grid container className={classes.container}>
    <div className={classes.logotype}>
      <img className={classes.logotypeIcon} src={logo} alt="logo" />
      <Typography variant="h3" color="white" className={classes.logotypeText}>
        Аналитическая Система Регулируемой Организации
      </Typography>
    </div>
    <Paper classes={{ root: classes.paperRoot }}>
      <Typography variant="h1" color="primary" className={classnames(classes.textRow, classes.errorCode)}>
        404
      </Typography>
      <Typography variant="h5" color="primary" className={classes.textRow}>
        Указанная страница не существует
      </Typography>
      <Typography variant="h6" color="textSecondary" className={classnames(classes.textRow, classes.safetyText)}>
        Вы можете вернуться на главную
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/main"
        size="large"
        className={classes.backButton}
      >
        На главную
      </Button>
    </Paper>
  </Grid>
);

export default withStyles(styles, { withTheme: true })(NotFound404);
