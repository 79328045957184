import React from 'react';

import { Table } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core';

import styles from './styles';

/**
 * Компонент для отображения фильтров в столбцах таблицы.
 *
 * Особенностью компонента является то, что при переходе страницы в режим печати, он не отображается.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PrintableRow = ({ children, tableRow, classes, ...props }) => (
  <Table.Row {...props} className={classes.printableRow}>
    {children}
  </Table.Row>
);

PrintableRow.propTypes = {};

PrintableRow.defaultProps = {};

export default withStyles(styles)(PrintableRow);
