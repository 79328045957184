import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';

const Dot = ({ classes, size, color, theme }) => (
  <div
    className={classnames(classes.dotBase, {
      [classes.dotLarge]: size === 'large',
    })}
    style={{ backgroundColor: color && theme.palette[color] && theme.palette[color].main }}
  />
);

export default withStyles(styles, { withTheme: true })(Dot);
