import { createStyles } from '@material-ui/core';

export default (theme) => createStyles({
  root: {
    display: 'flex',
    maxWidth: '100vw',
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    paddingLeft: '96px',
    '@media print': {
      paddingLeft: 0,
    },
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing.unit * 6}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
    '@media print': {
      display: 'none',
    },
  },
});
