import { compose, withHandlers, withState } from 'recompose';

import SidebarLinkView from './SidebarLinkView';

export default compose(
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    toggleCollapse: ({ setIsOpen, isSidebarOpened, isOpen }) => (e) => {
      if (isSidebarOpened) {
        e.preventDefault();
        setIsOpen(!isOpen);
      }
    },
  }),
)(SidebarLinkView);
