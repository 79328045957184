import React from 'react';

import { withStyles } from '@material-ui/core';
import { Toolbar } from '@devexpress/dx-react-grid-material-ui';

import ExportCSVButton from '../../../../../../../../ExportCSVButton';

import { CSVHeader } from './lib/utils';

import styles from './styles';


/**
 * Компонент отвечающий за наполнение Toolbar.
 *
 * @property {Array} exportData - Данные, которые будут переданы на экспорт в CSV;
 * @property {Array} sourceColumns - Массив объектов, которые используются в качестве заголовков в таблице;
 * @property {Array} bands - Массив объектов, который показывает, какие заголовки объединены в группы.
 * @property {Array} hiddenColumnNames - Массив строк, с названием колонок которые скрыты.
 */
const ToolbarFilling = ({ children, exportData, bands, classes, sourceColumns, hiddenColumnNames, ...props }) => {
  const columns = sourceColumns.filter((column) => hiddenColumnNames.indexOf(column.name) === -1);
  const headers = CSVHeader(columns, bands);

  return (
    <Toolbar.Root className={classes.toolBar} {...props} >
      <ExportCSVButton
        data={exportData}
        header={headers}
        {...props}
      />
      {children}
    </Toolbar.Root>
)};

export default withStyles(styles)(ToolbarFilling);
