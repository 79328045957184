import React, { StrictMode } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import themes, { overrides } from './themes';
import Layout from './components/Layout/LayoutContainer';
import NotFound404 from './components/NotFound404/NotFound404';
import Login from './components/Login/LoginContainer';
import auth, { TOKEN } from './lib/auth';
import session from './lib/session';

const theme = createMuiTheme({ ...themes.default, ...overrides });

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest} render={props => {
      const token = localStorage.getItem(TOKEN)
      if (token) session.defaults.headers.common['Authorization'] = `Token ${token}`;
      return (
        auth.checkToken()
          ? (
            React.createElement(component, props)
          )
          : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
      )
    }}
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest} render={props => {
      const token = localStorage.getItem(TOKEN)
      if (token) session.defaults.headers.common['Authorization'] = `Token ${token}`;
      return (
        auth.checkToken()
          ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )
          : (
            React.createElement(component, props)
          )
      )
    }}
    />
  );
};

const App = () => (
  <StrictMode>
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/main/tariffs" />} />
          <Route exact path="/main" render={() => <Redirect to="/main/tariffs" />} />
          <PrivateRoute path="/main" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <Route component={NotFound404} />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  </StrictMode>
);

export default App;
