import { createStyles } from '@material-ui/core';

const elementHeight = window.innerHeight - 64

export default (theme) => createStyles({
  paperMain: {
    minHeight: elementHeight,
    padding: theme.spacing.unit,
    '@media print': {
      height: '100%',
    },
  },
  year: {
    width: 70,
    marginLeft: 10,
  }
});
