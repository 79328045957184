/**
 * Функция, возвращающая базовую часть API-url'а
 * @returns {string}
 */
export function getBaseUrl() {
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_DEPLOY_MODE === 'master') {
      return 'https://asro.itc.fas.gov.ru';
    }
    return 'http://10.201.0.62:85';
  }
  return 'http://localhost:8000';
}

/** Словарь месяцев по номеру */
export const months = {
  '01': 'Январь',
  '02': 'Февраль',
  '03': 'Март',
  '04': 'Апрель',
  '05': 'Май',
  '06': 'Июнь',
  '07': 'Июль',
  '08': 'Август',
  '09': 'Сентябрь',
  '10': 'Октябрь',
  '11': 'Ноябрь',
  '12': 'Декабрь',
}
