import React from 'react';

import { TemplateConnector, Plugin } from '@devexpress/dx-react-core';

/**
 *
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CalculationProvider = ({ bands, columns, values, period, plugin: Calculation, onChange }) => (
  <Plugin>
    <TemplateConnector>{
      ({ hiddenColumnNames }) => (
        <Calculation
          bands={bands}
          columns={columns}
          period={period}
          hiddenColumns={hiddenColumnNames}
          values={values}
          onChange={onChange}
        />
      )
    }</TemplateConnector>
  </Plugin>
);

CalculationProvider.propTypes = {};

CalculationProvider.defaultProps = {};

export default CalculationProvider;
