import { createStyles } from '@material-ui/styles';

export const styles = theme => createStyles({
  dotBase: {
    width: 5,
    height: 5,
    backgroundColor: theme.palette.text.hint,
    borderRadius: '50%',
    transition: theme.transitions.create('background-color'),
  },
  dotLarge: {
    width: 8,
    height: 8,
  },
});
