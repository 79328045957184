import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { CssBaseline, withStyles } from '@material-ui/core';

import Header from '../Header/HeaderContainer';
import Sidebar from '../Sidebar/SidebarContainer';
import DrainageTariff from '../Tariffs/Drainage';
import ElectricityTariff from '../Tariffs/Electricity';
import ElectricityNasTariff from '../Tariffs/ElectricityNas';
import GarbageTariff from '../Tariffs/Garbage';
import GasTariff from '../Tariffs/Gas';
import HotWaterSupplyTariff from '../Tariffs/HotWaterSupply';
import WarmTariff from '../Tariffs/Warm';
import WaterSupplyTariff from '../Tariffs/WaterSupply';
import CostTariff from '../Tariffs/Cost';
import BalanceTariff from '../Tariffs/Balance';
import ReagentCostTariff from '../Tariffs/ReagentCost';

import styles from './styles';

const Layout = ({ classes }) => (
  <div className={classes.root}>
    <CssBaseline/>
    <BrowserRouter>
      <>
        <Header/>
        <Sidebar/>
        <div className={classes.content}>
          <div className={classes.fakeToolbar} />
          <Switch>
            {/** Урлы главных пунктов меню. Редиректят на первый пункт из вложенного списка */}
            <Route exact path="/main/tariffs" render={() => <Redirect to="/main/tariffs/warm"/>}/>
            {/** Урлы вложенных пунктов меню */}
            <Route path="/main/tariffs/vo" component={DrainageTariff}/>
            <Route path="/main/tariffs/ee" component={ElectricityTariff}/>
            <Route path="/main/tariffs/otko" component={GarbageTariff}/>
            <Route path="/main/tariffs/gas" component={GasTariff}/>
            <Route path="/main/tariffs/hvs" component={HotWaterSupplyTariff}/>
            <Route path="/main/tariffs/warm" component={WarmTariff}/>
            <Route path="/main/tariffs/vs" component={WaterSupplyTariff}/>
            {/*<Route path="/main/tariffs/cost" component={CostTariff}/>*/}
            {/*<Route path="/main/tariffs/balance" component={BalanceTariff}/>*/}
            {/*<Route path="/main/tariffs/reagentCost" component={ReagentCostTariff}/>*/}

            <Route exact path="/main/electricity" render={() => <Redirect to="/main/electricity/nas"/>}/>
            <Route path="/main/electricity/nas" component={ElectricityNasTariff}/>
          </Switch>
        </div>
      </>
    </BrowserRouter>
  </div>
);

export default withStyles(styles)(Layout);
