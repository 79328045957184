import React, { useCallback } from 'react';
import { func } from 'prop-types';

import { Plugin } from '@devexpress/dx-react-core';
import { FilteringState } from '@devexpress/dx-react-grid';
import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';

import { and, icnt, or } from 'handy-filter';

import PrintableRow from './components/PrintableRow';
import { MESSAGES } from './lib/constants';

/**
 * Компонент фильтрации.
 *
 * @param onChange {function} - Обработчик изменения фильтров;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Filtering = ({ onChange }) => {
  const onChangeHandler = useCallback((filters) => {
    const conditions = filters.map(({ columnName, value }) => {
      if (columnName === 'name') {
        const values = value.split(',').map(v => v.trim()).filter(v => Boolean(v));
        return or(...values.map(v => icnt(columnName, v)));
      }
      return icnt(columnName, value)});
    onChange(and(...conditions));
  }, [onChange]);

  return (
    <Plugin>
      <FilteringState onFiltersChange={onChangeHandler}/>
      <TableFilterRow messages={MESSAGES} rowComponent={PrintableRow}/>
    </Plugin>
  );
};

Filtering.propTypes = {
  onChange: func.isRequired,
};

Filtering.defaultProps = {};

export default Filtering;
