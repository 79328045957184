export const COLUMNS = [
  { name: 'year', title: 'Год', remove: true },
  { name: 'name', title: 'Наименование' },
  { name: 'price_gor_gas_start', title: 'Первое полугодие' },
  { name: 'price_gor_gas_end', title: 'Второе полугодие' },
  { name: 'price_gor_ee_start', title: 'Первое полугодие' },
  { name: 'price_gor_ee_end', title: 'Второе полугодие' },
  { name: 'price_sel_start', title: 'Первое полугодие' },
  { name: 'price_sel_end', title: 'Второе полугодие' },
];

export const BANDS = [
  {
    title: 'Тарифы для населения, город, газовая плита (руб/кВтч). С НДС',
    children: [{ columnName: 'price_gor_gas_start' }, { columnName: 'price_gor_gas_end' }],
  },
  {
    title: 'Тарифы для населения, город, электрическая плита (руб/кВтч). С НДС',
    children: [{ columnName: 'price_gor_ee_start' }, { columnName: 'price_gor_ee_end' }],
  },
  {
    title: 'Тарифы для населения, село (руб/кВтч). С НДС',
    children: [{ columnName: 'price_sel_start' }, { columnName: 'price_sel_end' }],
  },
];
