import React, { useCallback, useState, useEffect, useRef } from 'react';
import { arrayOf, func, node, string } from 'prop-types';

import { withStyles } from '@material-ui/core';
import BaseSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './styles';

/**
 * Компонент для отображения выпадающего списка.
 *
 * @param items {Array<string>} - Список значений, который необходимо отобразить;
 * @param onChange {function} - Обработчик изменения выбранного значения;
 * @param [className] {string} - Названия CSS классов;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Select = ({ className, classes, items, onChange }) => {
  const [current, setCurrent] = useState(0);
  const firstRender = useRef(true);

  const onChangeHandler = useCallback(({ target: { value: idx }}) => {
    setCurrent(idx);
    onChange(items[idx]);
  }, [items, onChange]);

  useEffect(() => {
    if (items.length !== 0) {
      const length = items.length - 1;

      if (firstRender.current) {
        firstRender.current = false;
      } else {
        setCurrent(length);
        onChange(items[length]);
      }
    }
  }, [items])

  return (
    <>
      <BaseSelect className={`${className} ${classes.default}`} value={current} onChange={onChangeHandler}>
        {items.map((value, idx) => (
          <MenuItem key={idx} value={idx}>{value}</MenuItem>
        ))}
      </BaseSelect>

      <span className={`${className} ${classes.print}`}>{items[current]}</span>
    </>
  );
};

Select.propTypes = {
  items: arrayOf(node).isRequired,
  onChange: func.isRequired,
  classNames: string,
};

Select.defaultProps = {
  className: undefined
};

export default withStyles(styles)(Select);
