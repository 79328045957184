import { createStyles } from '@material-ui/styles';

export const styles = theme => createStyles({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotype: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 12,
    [theme.breakpoints.down("sm")]: {
      display: 'none',
    },
  },
  logotypeText: {
    fontWeight: 500,
    color: 'white',
    marginLeft: theme.spacing.unit * 2,
  },
  logotypeIcon: {
    width: 150,
    height: 'auto',
    marginBottom: theme.spacing.unit * 4,
  },
  paperRoot: {
    // boxShadow: theme.customShadows.widgetDark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 6,
    paddingRight: theme.spacing.unit * 6,
    maxWidth: 404,
  },
  textRow: {
    marginBottom: theme.spacing.unit * 10,
    textAlign: 'center',
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,
    color: theme.palette.text.hint,
  },
  backButton: {
    // boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    fontSize: 22,
  },
});
