import React from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Inbox as InboxIcon } from '@material-ui/icons';

import Dot from '../Dot/Dot';
import { styles } from './styles';

const SidebarLink = (
  {
    link,
    icon,
    label,
    children,
    location,
    classes,
    isSidebarOpened,
    nested,
    type,
    isOpen,
    toggleCollapse,
    toggleSidebar,
  },
) => {
  const isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === 'title')
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === 'divider') return <Divider className={classes.divider} />;

  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        onClick={toggleSidebar}
        to={link}
        className={classes.link}
        classes={{
          root: classnames({
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested
            ? <Dot color={isLinkActive && 'primary'} />
            : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  /**
   * Функция, обрабатывающая клик по ссылке в боковом меню
   * @param e
   */
  const listItemClick = (e) => {
    // Если меню открыто, то просто открываем вложенный список
    if (isSidebarOpened) {
      toggleCollapse(e);
      // Если меню закрыто, и "детей" в ссылках меньше 2, то раскрываем меню, а через 0,5 сек раскрываем список
    } else if (children.length > 1) {
      toggleSidebar(e);
      // Если список ещё не был раскрыт, то раскрываем его
      if (!isOpen) setTimeout(() => toggleCollapse(e), 500)
    }
  }

  return (
    <React.Fragment>
      <ListItem
        button
        component={link && Link}
        onClick={listItemClick}
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon
            ? icon
            : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout='auto'
          unmountOnExit
          className={classes.nestedList}
        >
          <List component='div' disablePadding>
            {children.map(childrenLink => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                toggleSidebar={toggleSidebar}
                nested
                {...childrenLink}
              />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(SidebarLink);
