import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginView from './LoginView';
import { loginUser, resetError } from './LoginState';

export default compose(
  connect(
    state => ({
      isLoading: state.login.isLoading,
      isAuthenticated: state.login.isAuthenticated,
      error: state.login.error,
    }),
    { loginUser, resetError },
  ),
  withRouter,
  withState('username', 'setUsername', ''),
  withState('password', 'setPassword', ''),
  withHandlers({
    handleInput: ({ error, resetError, setUsername, setPassword }) => (input = 'username') => (e) => {
      if (error) {
        resetError();
      }
      if (input === 'username') {
        setUsername(e.target.value);
      } else if (input === 'password') {
        setPassword(e.target.value);
      }
    },
    handleLoginButtonClick: ({ loginUser, username, password }) => () => {
      loginUser(username, password);
    },
    handlePressEnter: ({ loginUser, username, password }) => (event) => {
      if (event.key === 'Enter' && username !== '' && password !== '') {
        loginUser(username, password);
      }
    }
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { error, setPassword } = this.props;
      if (!error && nextProps.error) {
        setPassword('');
      }
    },
  }),
)(LoginView);
