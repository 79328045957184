import React from 'react';

import { Plugin } from '@devexpress/dx-react-core';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';

export { default as SortingLabel } from './components/Label';

/**
 * Компонент сортировки.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Sorting = () => (
  <Plugin>
    <SortingState/>
    <IntegratedSorting/>
  </Plugin>
);

Sorting.propTypes = {};

Sorting.defaultProps = {};

export default Sorting;
