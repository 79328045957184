import React from 'react';

import { Plugin } from '@devexpress/dx-react-core';
import { IntegratedPaging, PagingState } from '@devexpress/dx-react-grid';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';

import PrintablePanel from './components/PrintablePanel';
import { MESSAGES, PAGE_SIZES } from './lib/constants';

/**
 * Компонент пагинации.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Paging = () => (
  <Plugin>
    <PagingState defaultCurrentPage={0} defaultPageSize={PAGE_SIZES[0]}/>
    <IntegratedPaging/>
    <PagingPanel containerComponent={PrintablePanel} pageSizes={PAGE_SIZES} messages={MESSAGES}/>
  </Plugin>
);

Paging.propTypes = {};
Paging.defaultProps = {};

export default Paging;
