export const COLUMNS = [
  { name: 'mo_name', title: 'Муниципальное образование, наименование' },
  { name: 'mr_name', title: 'Муниципальный район, наименование' },
  { name: 'org_name', title: 'Справочник регулируемых организаций, наименование' },
  { name: 'l8_2', title: 'Вид(-ы) теплоносителя(-ей) / Вид воды / Вид сточных вод / Тип системы' },
  { name: 'l4', title: 'Признак плательщика НДС', defaultHide: true },
  { name: 'l8_7', title: 'Наличие статуса ЕТО/ГП/РО', defaultHide: true },
  { name: 'l_prevailing_share_value', title: 'Государственное и (или) муниципальное участие в юридическом лице. Преобладающий тип собственности в юридическом лице', defaultHide: true },
  { name: 'l_state_share_existence', title: 'Государственное и (или) муниципальное участие в юридическом лице. Наличие', defaultHide: true },
  { name: 'l_state_share_value', title: 'Государственное и (или) муниципальное участие в юридическом лице. Сведения о доле', defaultHide: true },
  { name: 'l10', title: 'Метод установления тарифа', defaultHide: true },
  { name: 'l12_1', title: 'Справочно: срок действия тарифов. Количество лет', defaultHide: true },
  { name: 'l12_2_1', title: 'Справочно: срок действия тарифов. Дата начала. Год', defaultHide: true },
  { name: 'l12_3_1', title: 'Справочно: срок действия тарифов. Дата окончания. Год', defaultHide: true },
  { name: 'l13_1', title: 'Общая информация по МО. Наличие программы комплексного развития', defaultHide: true },
  { name: 'l13_2', title: 'Общая информация по МО. Наличие схемы теплоснабжения/водоснабжения/водоотведения', defaultHide: true },
  { name: 'l45_1', title: 'Численность проживающих. Общее число жителей в МО', defaultHide: true },
  { name: 'l45_2', title: 'Численность проживающих. Число жителей, которым оказываются услуги', defaultHide: true },
  { name: 'l8_1', title: 'Перепродажа (для ТС, ВС, ВО)', defaultHide: true },
];

export const BANDS = [
  {
    title: 'Расходы на энергетические ресурсы и холодную воду. электроэнергия . Объём покупной энергии. Объём покупной энергии по одноставочному тарифу, тыс.кВт*ч',
    children: [],
    dynamic: true,
    name: 'l_p16_ee_1_2_1_1_1'
  },
  {
    title: 'Расходы на энергетические ресурсы и холодную воду. электроэнергия . Тариф на электроэнергию и мощность. по одноставочному тарифу. Средний одноставочный тариф на электрическую энергию, руб./кВт*ч',
    children: [],
    dynamic: true,
    name: 'l_p16_ee_1_2_1_2_1_6'
  },
  {
    title: 'Расходы на энергетические ресурсы и холодную воду. электроэнергия . Тариф на электроэнергию и мощность. по двухставочному тарифу. ставка за электроэнергию, руб./кВт*мес',
    children: [],
    dynamic: true,
    name: 'l_p16_ee_1_2_1_2_2_2'
  },
  {
    title: 'Расходы на энергетические ресурсы и холодную воду. электроэнергия . Затраты на покупку энергии по одноставочному тарифу, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_ee_1_2_1_3'
  },
  {
    title: 'Расходы на энергетические ресурсы и холодную воду. электроэнергия. Затраты на покупку мощности по двухставочному тарифу, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_ee_1_2_1_4'
  },
  {
    title: 'Расходы на энергетические ресурсы и холодную воду. электроэнергия . Затраты на покупку энергии по двухставочному тарифу, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_ee_1_2_1_5'
  },
  {
    title: 'Прибыль, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_pr'
  },
  {
    title: 'Производственные расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1'
  },
  {
    title: 'Прочие неподконтрольные расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_10'
  },
  {
    title: 'Производственные расходы. Расходы на приобретение сырья и материалов и их хранение, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_1'
  },
  {
    title: 'Производственные расходы. Расходы на приобретение сырья и материалов и их хранение. Реагенты , тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_1_1'
  },
  {
    title: 'Производственные расходы. Расходы на приобретение сырья и материалов и их хранение. Горюче-смазочные материалы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_1_2'
  },
  {
    title: 'Производственные расходы. Расходы на энергетические ресурсы и холодную воду, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_2'
  },
  {
    title: 'Недополученные доходы / Выпадающие расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_12'
  },
  {
    title: 'Производственные расходы. Расходы на энергетические ресурсы и холодную воду. электроэнергия , тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_2_1'
  },
  {
    title: 'Производственные расходы. Расходы на оплату работ и услуг, выполняемые сторонними организациями и индивидуальными предпринимателями, связанные с эксплуатацией централизованных систем либо объектов в составе таких систем, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_3'
  },
  {
    title: 'Производственные расходы. Расходы на оплату работ и услуг, выполняемые сторонними организациями и индивидуальными предпринимателями, связанные с эксплуатацией централизованных систем либо объектов в составе таких систем, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_4'
  },
  {
    title: 'Производственные расходы. Общехозяйственные расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_6'
  },
  {
    title: 'Производственные расходы. Прочие производственные расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_7'
  },
  {
    title: 'Производственные расходы. Цеховые расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_1_8'
  },
  {
    title: 'Ремонтные расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_2'
  },
  {
    title: 'Ремонтные расходы. Расходы на текущий ремонт централизованных систем водоснабжения и (или) водоотведения либо объектов, входящих в состав таких систем, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_2_1'
  },
  {
    title: 'Ремонтные расходы. Расходы на капитальный ремонт централизованных систем водоснабжения и (или водоотведения) либо объектов, входящих в состав таких систем, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_2_2'
  },
  {
    title: 'Ремонтные расходы. Расходы на оплату труда и отчисления на социальные нужды ремонтного персонала, в том числе налоги и сборы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_2_3'
  },
  {
    title: 'Административные расходы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_3'
  },
  {
    title: 'Административные расходы. Расходы на оплату работ и услуг, выполняемых сторонними организациями, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_3_1'
  },
  {
    title: 'Административные расходы. Расходы на оплату труда и отчисления на социальные нужды административно-управленческого персонала, в том числе налоги и сборы, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_3_2'
  },
  {
    title: 'Сбытовые расходы гарантирующих организаций, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_4'
  },
  {
    title: 'Амортизация, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_5'
  },
  {
    title: 'Расходы на арендную плату, лизинговые платежи, концессионную плату, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_6'
  },
  {
    title: 'Расходы, связанные с уплатой налогов и сборов, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_7'
  },
  {
    title: 'Расходы на оплату товаров (услуг, работ), приобретаемых у других организаций, осуществляющих регулируемые виды деятельности, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_8'
  },
  {
    title: 'Расходы на обслуживание бесхозяйных сетей, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_p16_9'
  },
  {
    title: 'Полезный отпуск продукции всего, тыс. куб.м',
    children: [],
    dynamic: true,
    name: 'l13'
  },
  {
    title: 'Себестоимость. Избыток средств, полученный за отчётные периоды регулирования, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l2_19'
  },
  {
    title: 'Перекрёстное субсидирование, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'cl4'
  },
  {
    title: 'Итого расходы на реализацию, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l_costs_on_sale'
  },
  {
    title: 'Прочие. средневзвешенный тариф, руб./куб.м',
    children: [],
    dynamic: true,
    name: 'l24_4_1'
  },
  {
    title: 'Население. средневзвешенный тариф, руб./куб.м',
    children: [],
    dynamic: true,
    name: 'l24_3_1'
  },
  {
    title: 'Бюджетные потребители. средневзвешенный тариф, руб./куб.м',
    children: [],
    dynamic: true,
    name: 'l24_2_1'
  },
  {
    title: 'Организации-перепродавцы. средневзвешенный тариф, руб./куб.м',
    children: [],
    dynamic: true,
    name: 'l24_1_1'
  },
  {
    title: 'Необходимая валовая выручка, с НДС, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l6'
  },
  {
    title: 'Необходимая валовая выручка, без НДС, тыс.руб.',
    children: [],
    dynamic: true,
    name: 'l5'
  },
];
