/**
 * Функция формирует массив объектов, которые будут использоваться для формирования заголовков в CSV выгрузке.
 *
 * @param {Array} columns - Массив объектов, содержащий информацию о всех колонках;
 * @param {Array} bands - Массив объектов, содержащий информацию о группировке колонок;
 * @returns - Массив объектов, который содержит правильные названия для заголовков.
 */
export const CSVHeader = (columns, bands) => {
  let transformColumns = [];
  for (const column of columns) {
    const transformColumn = {...column};
    for (const band of bands) {
      for (const bandColumn of band.children) {
        if (column.name === bandColumn.columnName)
          transformColumn.title += ` - ${band.title}`;
      }
    }
    transformColumns.push(transformColumn);
  }
  return transformColumns;
}
