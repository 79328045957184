/**
 * Функция для получения настроек для столбцов таблицы по их описанию.
 *
 * @param columns {Array<{ name: string, title: React.ReactNode }>} - Описание столбцов таблицы;
 *
 * @return {Array<{ columnName: string, align?: string, wordWrapEnabled?: boolean }>}
 */
export const getColumnExtensions = (columns, width = 'auto') => {
  const extensions = [];

  for (const { name } of columns) {
    if (name !== 'name') {
      extensions.push({ columnName: name, align: 'center', wordWrapEnabled: true, width });
    } else {
      extensions.push({ columnName: name, wordWrapEnabled: true });
    }
  }

  return extensions;
};

/**
 * Функция для парсинга данных по тарифам.
 *
 * Группирует записи по годам и создает список федеральных округов.
 *
 * @param entries {Array<{ year: string | number, district: string }>} - ;
 *
 * @return - Массив:
 *  1) Объект ключами, которого являются года, а значениями - списки объектов;
 *  2) Список федеральных округов.
 */
export const parse = (entries) => {
  const groupedByYear = {};
  const districts = new Set();

  for (const { year, ...entry } of entries) {
    if (!(year in groupedByYear)) {
      groupedByYear[year] = [];
    }

    groupedByYear[year].push(entry);
    districts.add(entry.district);
  }

  return [groupedByYear, [...districts]];
}

/**
 * Функция удаляет из массива объекты, которые должны быть скрыты.
 *
 * Объект, который должен быть скрыт, должен содержать поле hide, установленное в true.
 *
 * @param {Array} toHide - массив из которого должны быть удалены объекты на скрытие.
 */
export const removeColumns = (toHide) => toHide.filter((obj) => !obj.remove);
