import React from 'react';

import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@material-ui/core';

import styles from './styles';

/**
 * Компонент для отображения панели пагинации.
 *
 * Особенностью компонента является то, что при переходе страницы в режим печати, он не отображается.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PrintablePanel = ({ children, classes, ...props }) => (
  <PagingPanel.Container {...props} className={classes.printablePanel}>
    {children}
  </PagingPanel.Container>
);

PrintablePanel.propTypes = {};

PrintablePanel.defaultProps = {};

export default withStyles(styles)(PrintablePanel);
