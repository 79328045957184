import React from 'react';

import TableTemplate from '../TableTemplate';

import { BANDS, COLUMNS } from './lib/constants';

/**
 * Компонент для отображения данных тарифов водоснабжения.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const WaterSupplyView = () => (
  <TableTemplate
    url="tariffs/vs"
    title="Тарифы в сфере водоснабжения за"
    columns={COLUMNS}
    bands={BANDS}
  />
);

WaterSupplyView.propTypes = {};

WaterSupplyView.defaultProps = {};

export default WaterSupplyView;
