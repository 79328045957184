import React from 'react';
import { Drawer, IconButton, List, withStyles } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, TableChart, FlashOn } from '@material-ui/icons';
import classNames from 'classnames';
import SidebarLink from './components/SidebarLink/SidebarLinkContainer';
import { styles } from './styles';

const structure = [
  {
    id: 5,
    label: 'Тарифы ЖКХ',
    icon: <TableChart/>,
    children: [
      { label: 'Теплоснабжение', link: '/main/tariffs/warm' },
      { label: 'Водоснабжение', link: '/main/tariffs/vs' },
      { label: 'Водоотведение', link: '/main/tariffs/vo' },
      { label: 'Горячее водоснабжение', link: '/main/tariffs/hvs' },
      { label: 'ТКО', link: '/main/tariffs/otko' },
      { label: 'Газ', link: '/main/tariffs/gas' },
      { label: 'Электрическая энергия', link: '/main/tariffs/ee' },
      // { label: 'Калькуляция затрат водоотведения', link: '/main/tariffs/cost' },
      // { label: 'Баланс водоотведения', link: '/main/tariffs/balance' },
      // { label: 'Расходы на реагенты', link: '/main/tariffs/reagentCost' },
      // { label: 'Тарифные решения', link: '/main/tariffs/tariffSolution' },
      // { label: 'Тарифные на передачу', link: '/main/tariffs/transferRate' },
      // { label: 'Тарифные на водоотведение', link: '/main/tariffs/waterDisposalRates' },
    ],
  },
  {
    id: 6,
    label: 'Электроэнергетика',
    link: '/main/electricity',
    icon: <FlashOn/>,
    children: [
      { label: 'Тарифы для населения (INFO.KU.EE.GAS.SF.TF)', link: '/main/electricity/nas' },
    ],
  },
  { id: 7, type: 'divider' },
];

const SidebarView = ({ classes, toggleSidebar, isSidebarOpened, isPermanent, location }) => {
  return (
    <Drawer
      variant={isPermanent
        ? 'permanent'
        : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton
          onClick={toggleSidebar}
        >
          <ArrowBackIcon classes={{ root: classNames(classes.headerIcon, classes.headerIconCollapse) }} />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => <SidebarLink key={link.id} location={location} toggleSidebar={toggleSidebar}
                                            isSidebarOpened={isSidebarOpened} {...link} />)}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(SidebarView);
