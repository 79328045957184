/**
 * Функция преобразовывающая массива данных в строку csv формата.
 *
 * @param {Array<Object>} data - Массив объектов, которые будут записаны в файл;
 * @param {Array<Object>} headers - Массив объектов, которые будут преобразованы в заголовок;
 *  В формате { name: 'name', title: 'Наименование' }
 * @returns {String} - строка в формате csv.
 */
const toCSV = (data, headers) => {
  let CSVHeader = '';
  if(headers) {
    for (const header of headers) {
      CSVHeader += `${header.title};`;
    }
  }
  let CSVData = '';
  if(headers) {
    // если переданы заголовки, то выводятся только колонки для которых есть наименование
    for (const row of data) {
      let CSVRow = '';
      for (const header of headers) {
        let value = row[header.name];
        if (typeof value === 'number') {
          if (isNaN(value) || !isFinite(value)) {
            value = 'Невозможно посчитать';
          }
        }
        if (value === undefined || value === null) value = 'Значение отсутствует';
        CSVRow += `${value};`;
      }
      CSVData += CSVRow + '\n';
    }
  } else {
    // если заголовки для колонок не переданы, то выводятся все данные
    for (const row of data) {
      const CSVRow = Object.values(row).join(';');
      CSVData += CSVRow + '\n';
    }
  }
  if (CSVHeader) {
    CSVData = CSVHeader + '\n' + CSVData;
  }
  return CSVData;
}

/**
 * Функция для получения названия файла из HTTP заголовка Content-Disposition.
 *
 * @param contentDisposition {string} - HTTP заголовок, содержащий название файла.
 * @return {string} - Название файла.
 */
 const getFilename = (contentDisposition) => {
  if (contentDisposition.includes('filename*=')) {
    return decodeURIComponent(contentDisposition.match(/filename\*=.*'(.*)/)[1]);
  }

  return contentDisposition.match(/filename="(.*)"/)[1];
};

/**
 * Функция для сохранения файла на компьютер пользователя.
 *
 * Функция сохраняет файл прямиком из объекта Ответа от сервера.
 *
 * @param data {Blob} - Содержимое файла;
 * @param headers {{ 'content-disposition': string }} - HTTP Заголовки ответа от сервера.
 */
const saveToDisk = ({ data, headers }) => {
  try {
    const filename = getFilename(headers['content-disposition']);

    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(data, filename);
    } else {
      const link = document.createElement('a');

      link.setAttribute('href', URL.createObjectURL(data));
      link.setAttribute('download', filename);

      link.style.display = 'none';
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    console.log('Error')
  }
};

/**
 * Функция для сохранения файла на диск.
 *
 * Является оберткой для saveToDisk, с более упрощенным интерфейсом.
 *
 * @param {Blob} file - контент файла для сохранения;
 * @param {String} filename - Название файла
 */
const saveFile = (file, filename) => {
  saveToDisk({ data: file,
    headers: {
      'content-disposition': `filename="${filename}"`,
    },
  });
};

/**
 * Функция которая сохраняется данные в формате CSV в файле.
 *
 * @param {Array<Object>} data - массив объектов, которые будут записаны в файл
 * @param {Array<String>} headers - массив строк, которые будут преобразованы в заголовок.
 */
export const saveToCSV = (data, header) => {
  const CVS = toCSV(data, header);
  const blob = new Blob([CVS], {type : 'text/csv'});
  saveFile(blob, 'import.csv');
}
