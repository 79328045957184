import { createStyles } from '@material-ui/core';

export default () => createStyles({
  default: {
    '@media print': {
      display: 'none',
    },
  },
  print: {
    display: 'none',
    '@media print': {
      display: 'inline',
    },
  },
});
