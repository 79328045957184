import React from 'react';

import { withStyles } from '@material-ui/core';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import styles from './styles';

/**
 * Компонент для отображения заголовка столбца с сортировкой.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Label = ({ children, classes, ...props}) => (
  <TableHeaderRow.SortLabel className={classes.sortLabel} {...props}>
    {children}
  </TableHeaderRow.SortLabel>
);

Label.propTypes = {};

Label.defaultProps = {};

export default withStyles(styles)(Label);
