import React from 'react';

import TableDynamicsOfChange from '../TableTemplate/TableDynamicsOfChange';
import CalculationByYear from '../TableTemplate/TableDynamicsOfChange/components/Calculations/CalculationByYear';

import { COLUMNS, BANDS } from './lib/constants';

/**
 * Компонент для отображения таблицы Калькуляции затрат.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ReagentCost = () => (
  <TableDynamicsOfChange
    url="tariffs/vo_r_diff"
    title="Расходы на реагенты"
    columns={COLUMNS}
    bands={BANDS}
    plugin={CalculationByYear}
  />
);

ReagentCost.propTypes = {};

ReagentCost.defaultProps = {};

export default ReagentCost;
