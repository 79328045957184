export const COLUMNS = [
  { name: 'year', title: 'Год', remove: true },
  { name: 'name', title: 'Наименование' },
  { name: 'l_tf_no_tax_handling_start', title: 'Первое полугодие' },
  { name: 'l_tf_no_tax_handling_end', title: 'Второе полугодие' },
  { name: 'l_tf_tax_disposal_start', title: 'Первое полугодие' },
  { name: 'l_tf_tax_disposal_end', title: 'Второе полугодие' },
  { name: 'l_ro_vlm_tf_no_tax_start', title: 'Первое полугодие' },
  { name: 'l_ro_vlm_tf_no_tax_end', title: 'Второе полугодие' },
  { name: 'sr_norm_otko_mh', title: 'Многоквартирные дома' },
  { name: 'sr_norm_otko_ph', title: 'Индивидуальные жилые дома' },
];

export const BANDS = [
  {
    title: 'Средневзвешенный тариф на обработку ТКО (руб/куб.м). Без НДС',
    children: [{ columnName: 'l_tf_no_tax_handling_start' }, { columnName: 'l_tf_no_tax_handling_end' }],
  },
  {
    title: 'Средневзвешенный тариф на захоронение ТКО (руб/Гкал). С НДС',
    children: [{ columnName: 'l_tf_tax_disposal_start' }, { columnName: 'l_tf_tax_disposal_end' }],
  },
  {
    title: 'Тарифы регионального оператора по обращению с ТКО (руб/куб.м | руб/тонна). Без НДС',
    children: [{ columnName: 'l_ro_vlm_tf_no_tax_start' }, { columnName: 'l_ro_vlm_tf_no_tax_end' }],
  },
  {
    title: 'Среднемесячный норматив накопления ТКО (куб.м)',
    children: [{ columnName: 'sr_norm_otko_mh' }, { columnName: 'sr_norm_otko_ph' }],
  },
];
