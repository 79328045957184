import React, { useMemo } from 'react';

import { Plugin, Getter } from '@devexpress/dx-react-core';
import { TableColumnVisibility, Toolbar as DxToolbar, ColumnChooser } from '@devexpress/dx-react-grid-material-ui';

import { COLUMN_CHOOSER_MESSAGE } from '../../lib/constants';

import ToolbarComponent from './components/ToolbarComponent';
import ColumnChooserContainer from './components/ColumnChooserContainer';


/**
 * Компонент предоставляющий инструменты для управления таблицей.
 *
 * @param {Array} exportData - Данные которые необходимо экспортировать в CSV;
 * @param {Array} bands - Массив содержащий информацию о том, какие колонки объединены в группу;
 * @param {Array} sourceColumns - Все колонки, с учетом тех, что в таблице не показываются;
 * @param {Array} hideColumns - Колонки, которые используются в Chooser, для возможности скрытия их или визуализации;
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbar = ({ exportData, bands, sourceColumns, hideColumns }) => {
  const defaultHiddenColumnNames = useMemo(() => sourceColumns.filter(column => column.dynamic || column.defaultHide).map(column => column.name), [sourceColumns]);
  return (
  <Plugin>
    {/* Тут магия devexpress с получением данных, см. документацию https://devexpress.github.io/devextreme-reactive/react/core/docs/reference/action/ */}
    <Getter name="exportData" value={exportData}/>
    <Getter name="headerBands" value={bands}/>
    <Getter name="sourceColumns" value={sourceColumns}/>
    <Getter name="hideColumns" value={hideColumns}/>
    <TableColumnVisibility
      defaultHiddenColumnNames={defaultHiddenColumnNames}
      messages={COLUMN_CHOOSER_MESSAGE}
    />
    <DxToolbar rootComponent={ToolbarComponent}/>
    <ColumnChooser containerComponent={ColumnChooserContainer} messages={COLUMN_CHOOSER_MESSAGE}/>
  </Plugin>
)}

Toolbar.propTypes = {};

Toolbar.defaultProps = {};

export default Toolbar;
