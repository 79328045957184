import { createStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => createStyles({
  table: {
    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: fade(theme.palette.primary.main, 0.15),
    },
    '& > thead': {
      position: 'sticky',
      top: 63,
      backgroundColor: 'white',

      '@media print': {
        position: 'relative',
        top: 0,
      },
    },
  },
});
