import React from 'react';

import TableTemplate from '../TableTemplate';

import { BANDS, COLUMNS } from './lib/constants';

/**
 * Компонент для отображения данных тарифов на природный газ.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GasView = () => (
  <TableTemplate
    url="tariffs/gas"
    title="Тарифы на природный газ (на приготовление пищи и нагрев воды) за"
    columns={COLUMNS}
    bands={BANDS}
  />
);

GasView.propTypes = {};

GasView.defaultProps = {};

export default GasView;
