import React, { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import GetApp from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';

import { saveToCSV } from '../../lib/file';

import styles from './styles';

/**
 * Компонент, который отображает кнопку, которая производит загрузку CSV файла пользователю на компьютер.
 *
 * @property {Array<Object>} data - Массив объектов, где каждый объект - это строка, которая будет переформатирована в CSV формат;
 * @property {Array<Object>} header - Массив объектов, где каждый объект - это описание колонки заголовка;
 */
const ExportCSVButton = ({ data, header, message, classes, ...rest }) => {
  const onClickHandler = useCallback(() => {
    saveToCSV(data, header);
  }, [data, header]);

  return (
    <IconButton
      className={classes.csvButton}
      disabled={!data}
      onClick={onClickHandler}
      color="primary"
      {...rest}
    >
      <GetApp />
    </IconButton>
  )
}

export default withStyles(styles)(ExportCSVButton);
