import React from 'react';

import TableTemplate from '../TableTemplate';

import { BANDS, COLUMNS } from './lib/constants';

/**
 * Компонент для отображения данных тарифов горячего водоснабжения.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const HotWaterSupplyView = () => (
  <TableTemplate
    url="tariffs/hvs"
    title="Тарифы в сфере горячего водоснабжения (одноставочные) за"
    columns={COLUMNS}
    bands={BANDS}
  />
);

HotWaterSupplyView.propTypes = {};

HotWaterSupplyView.defaultProps = {};

export default HotWaterSupplyView;
