import React from 'react';

import { ColumnChooser } from '@devexpress/dx-react-grid-material-ui';
import { getColumnChooserItems } from './lib/utils';


/**
 * Контейнер, который отображает список колонок, которые могут быть закрыты.
 *
 * @param {Array} headerBands - Массив объектов, содержащий данные о группировки заголовков в группы;
 * @param {Array} columns - Массив объектов, содержащий информацию о колонках;
 *
 * NOTE: Встроенные методы и данные, предоставляемые dev express.
 * https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table-column-visibility/#plugin-developer-reference
 * @param {Array} hiddenColumnNames - Встроенный dev express массив, содержащий информацию о уже скрытых колонках;
 * @param {Callback} isColumnTogglingEnabled - Встроенная dev express функция, которая определяет, может ли пользователь определять видимость колонки;
 * @param {Callback} toggleColumnVisibility - Переключает видимость колонки.
 */
const Container = ({ headerBands, columns, hiddenColumnNames, isColumnTogglingEnabled, toggleColumnVisibility }) => {
  return (
    <ColumnChooser.Container>
      {getColumnChooserItems(headerBands, columns, hiddenColumnNames)
        .map((item) => {
          const { name: columnName } = item;
          const togglingEnabled = isColumnTogglingEnabled(columnName);
          return (
            <ColumnChooser.Item
              key={columnName || item.column.name}
              item={item}
              disabled={!togglingEnabled}
              onToggle={() => {
                if (item.columns) {
                  item.columns
                    .map(({ columnName }) => columnName)
                    .forEach(toggleColumnVisibility);
                } else {
                  toggleColumnVisibility(columnName);
                }
              }}
            />
          );
        })
      }
    </ColumnChooser.Container>
  );
}

export default Container;
