import React from 'react';

import TableDynamicsOfChange from '../TableTemplate/TableDynamicsOfChange';
import CalculationByYear from '../TableTemplate/TableDynamicsOfChange/components/Calculations/CalculationByYear';

import { COLUMNS, BANDS } from './lib/constants';

/**
 * Компонент для отображения таблицы Калькуляции затрат.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CostCalculation = () => (
  <TableDynamicsOfChange
    url="tariffs/vo_calc_diff"
    title="Калькуляция затрат в сфере водоотведения"
    columns={COLUMNS}
    bands={BANDS}
    plugin={CalculationByYear}
  />
);

CostCalculation.propTypes = {};

CostCalculation.defaultProps = {};

export default CostCalculation;
