import { compose, withHandlers, withState } from 'recompose';
import { connect } from 'react-redux';
import HeaderView from './HeaderView.';
import { toggleSidebar } from '../Layout/LayoutState';
import { signOut } from '../Login/LoginState';

export default compose(
  connect(
    state => ({
      isSidebarOpened: state.layout.isSidebarOpened,
    }),
    { toggleSidebar, signOut },
  ),
  withState('profileMenu', 'setProfileMenu', null),
  withHandlers({
    openProfileMenu: ({ setProfileMenu }) => event => {
      setProfileMenu(event.currentTarget);
    },
    closeProfileMenu: ({ setProfileMenu }) => () => {
      setProfileMenu(null);
    },
  }),
)(HeaderView);
