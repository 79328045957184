import React from 'react';
import classNames from 'classnames';
import { AppBar, IconButton, Menu, Toolbar, withStyles } from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
  Person as AccountIcon,
  ContactSupportOutlined as SupportIcon } from '@material-ui/icons';
import Typography from '../Wrappers/Wrappers';

import { styles } from './styles';
import ProfileMenu from './ProfileMenu';
import SupportMenu from './SupportMenu';

class Header extends React.Component {
  /**
   * Конструктор компонента с переопределением пропсов и стейтом.
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      menuContent: 'profile'
    };
  }

  /**
   * Метод возвращает node который должен быть установлен в выскакивающее меню.
   * @returns {Node}
   */
  setMenuContent = () => {
    const { menuContent } = this.state;
    switch (menuContent) {
      case 'profile':
        return <ProfileMenu signOut={this.props.signOut}/>;
      case 'support':
        return <SupportMenu />;
      default:
        return <ProfileMenu signOut={this.props.signOut}/>;
    }
  };

  /**
   * Метод устанавливает какая Node должна быть установлена в окне и посылает сигнал на открытие окна
   * @param event - событие клика
   * @param menuContent {string} - название node для отображения в модальном окне
   */
  openMenu = (event, menuContent) => {
    this.setState(state => ({ menuContent }));
    this.props.openProfileMenu(event);
  };

  render() {
    const {classes, isSidebarOpened, toggleSidebar} = this.props;
    const menuInfo = this.setMenuContent();
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={toggleSidebar}
            className={classNames(
              classes.headerMenuButton,
              classes.headerMenuButtonCollapse,
            )}
          >
            {isSidebarOpened
              ? (
                <ArrowBackIcon
                  classes={{
                    root: classNames(classes.headerIcon, classes.headerIconCollapse),
                  }}
                />
              )
              : (
                <MenuIcon
                  classes={{
                    root: classNames(classes.headerIcon, classes.headerIconCollapse),
                  }}
                />
              )}
          </IconButton>
          <Typography variant="h6" weight="medium" className={classes.logotype}>
            Аналитическая Система Регулируемой Организации
          </Typography>
          <div className={classes.grow}/>
          <IconButton
            className={classes.headerMenuButton}
            onClick={(e) => this.openMenu(e, 'support')}
          >
            <SupportIcon classes={{root: classes.headerIcon}}/>
          </IconButton>
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => this.openMenu(e, 'profile')}
          >
            <AccountIcon classes={{root: classes.headerIcon}}/>
          </IconButton>
          <Menu
            id="profile-menu"
            open={Boolean(this.props.profileMenu)}
            anchorEl={this.props.profileMenu}
            onClose={this.props.closeProfileMenu}
            className={classes.headerMenu}
            classes={{paper: classes.profileMenu}}
            disableAutoFocusItem
          >
            {menuInfo}
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}


export default withStyles(styles)(Header);
