import React from 'react';

import { TemplateConnector } from '@devexpress/dx-react-core';

import ToolbarFilling from './components/ToolbarFilling';

/**
 * Компонент для отображения полоски Toolbar и дополнительных инструментов управления таблицей в ней.
 */
const ToolbarComponent = (props) => (
  <TemplateConnector>
      {/* Тут магия devexpress с получением данных, см. документацию https://devexpress.github.io/devextreme-reactive/react/core/docs/reference/action/ */}
      { ({ exportData, headerBands, sourceColumns, hiddenColumnNames }) =>
          <ToolbarFilling exportData={exportData} sourceColumns={sourceColumns} hiddenColumnNames={hiddenColumnNames} bands={headerBands} {...props} />
      }
  </TemplateConnector>
)

export default ToolbarComponent;
