import React from 'react';

import TableTemplate from '../TableTemplate';

import { BANDS, COLUMNS } from './lib/constants';

/**
 * Компонент для отображения данных тарифов теплоснабжения.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const WarmView = () => (
  <TableTemplate
    url="tariffs/warm"
    title="Тарифы в сфере теплоснабжения за"
    columns={COLUMNS}
    bands={BANDS}
  />
);

WarmView.propTypes = {};

WarmView.defaultProps = {};

export default WarmView;
